<template lang="">
<div class="wrapper">
            <div class="oferta">
                <h1>Публичная оферта на заключение договора о предоставлении права использования сервисом «Limza.CHAT» и подключения к нему</h1>

Город Химки, редакция от 09.10.2022 года<br><br>

<p> Данный документ - официальное предложение Общества с ограниченной ответственностью «Лимза» (далее – «Лицензиар») любым юридическим лицам, индивидуальным предпринимателям и самозанятым лицам, зарегистрированным в соответствии с Федеральным законом от 27.11.2018 года N422-ФЗ, заключить договор о предоставлении права использования сервиса Limza.CHAT и подключении к сервису на условиях, изложенных в этой оферте. Настоящая оферта содержит все существенные условия договора.
В соответствии с пунктом 2 статьи 437 Гражданского кодекса Российской Федерации (ГК РФ) в случае принятия изложенных ниже условий лицо, производящее акцепт настоящей оферты, становится Лицензиатом, так как в соответствии с пунктом 3 статьи 438 ГК РФ акцепт оферты равносилен заключению договора на условиях, изложенных в оферте.
Поэтому внимательно прочитайте текст этой оферты. Если вы не согласны с каким-либо пунктом оферты, Лицензиар предлагает вам отказаться от покупки и использования сервиса Limza.CHAT.</p><br><br>
<p>
<strong>1. Термины и определения</strong><br>
1.1. Оферта — настоящее предложение Общества с ограниченной ответственностью «Лимза» любым юридическим лицам, индивидуальным предпринимателям и самозанятым лицам, зарегистрированным в соответствии с Федеральным законом от 27.11.2018 года N422-ФЗ, заключить договор о предоставлении права использования сервиса Limza.CHAT и подключения к нему.<br>
1.2. Акцепт Оферты — полное и безоговорочное принятие Оферты путем выполнения действий, указанных в разделе 6 Оферты. Акцептуя Оферту, Лицензиат заключает договор с Лицензиаром на условиях этой Оферты.<br>
1.3. Договор – договор о предоставлении права использования сервиса «Limza.CHAT» и подключения к нему между Лицензиатом и Лицензиаром, заключенный в момент Акцепта Оферты на условиях из этой Оферты. Договор является смешанным и включает условия лицензионного договора в части предоставления права использования сервиса Limza.CHAT и оказания услуг в части первичных услуг по подключению к сервису Limza.CHAT Чат-бота Лицензиата.<br>
1.4. Сервис — Limza.CHAT – это программное обеспечение, настраиваемое Лицензиаром для Чат-бота Лицензиата, позволяющее Лицензиату взаимодействовать со своими клиентами (пользователями). Лицензиар постоянно совершенствует Сервис, полный функционал Сервиса и предоставляемые возможности Сервиса можно узнать у Лицензиара. <br>
1.5. Чат-бот – программа в мессенджере «Telegram», зарегистрированная на имя Лицензиата, позволяющая показывать информацию подписчикам владельца чата-бота (Лицензиата).<br>
1.6. Лицензиар — ООО «Лимза», ИНН 5047265844, правообладатель исключительного права на Limza.CHAT, в том числе предоставляющий за вознаграждение право использования Сервиса и услуги по первичной настройке и подключению Чат-бота Лицензиата к Сервису, в соответствии с условиями Оферты.<br>
1.7. Лицензиат — юридическое лицо или индивидуальный предприниматель, или самозанятое лицо, зарегистрированное в соответствии с Федеральным законом от 27.11.2018 года N422-ФЗ, Акцептовавшее Оферту, а также сотрудники данного лица, правомерно использующие Сервис способами и в пределах, установленных Договором.<br>
1.8. Неисключительная Лицензия — предоставление Лицензиату права использования Сервиса с сохранением за Лицензиаром права выдачи лицензий другим лицам.<br>
1.9. Информация — любые сведения и контент, размещенные на Сервисе.<br>
1.10. Учетная запись — запись, хранящаяся на сайте Сервиса, содержащая сведения для идентификации Лицензиата для использования Сервиса, а также информацию для Авторизации и учета в Сервисе. К такой записи, в том числе относятся имя пользователя (логин) и пароль (или другие аналогичные средства аутентификации).<br>
1.11. Авторизация — введение логина и пароля Лицензиатом в Сервисе, дающее возможность использовать Сервис.<br><br>

<strong>2. Предмет договора</strong><br>
2.1. Лицензиар предоставляет Лицензиату за вознаграждение в порядке и на условиях из Договора:<br>
2.1.1. услуги по подключению Чат-бота Лицензиата (в этой части к Договору применяется законодательство об оказании услуг);<br>
2.1.2. неисключительные срочные права (простая неисключительная лицензия) на использование Сервиса на территории всего мира (в этой части к Договору применяется законодательство о договоре неисключительной лицензии, а также иные относимые положения части 4 Гражданского Кодекса РФ).<br>
2.2. Доступ к Сервису может быть предоставлен сроком от одного месяца до года с возможностью пролонгации.<br>
2.3. Подключение к сервису Limza.CHAT включает в себя:<br>
- инструкцию для регистрации Чат-бота в «Telegram»;<br>
- подключение Чат-бота к сервису Limza.CHAT путем указания уникального токена Чат-бота в программном обеспечении ООО «Лимза»;<br>
- предоставление Лицензиату логина, пароля и уникальной ссылки в сети интернет для доступа к web-сервису Limza.CHAT;<br>
- наполнение контентом Лицензиара заранее созданного шаблона в web-меню Чат-бота.<br>
Подключение к Сервису означает согласие Лицензиата с шаблоном web-меню Чат-бота.<br>
2.4. Сервис включает следующий набор функций:<br>
2.4.1 Авторизация в Учетной записи:
Защищенный протокол передачи данных HTTPS;<br>
2.4.2. База клиентов:
Поиск по базе;
Просмотр подробной информации о клиенте;
Добавление, редактирование информации о клиенте;
Просмотр истории изменения клиента;<br>
2.4.3. База сотрудников:
Добавление, удаление, редактирование сотрудников;
Предоставление, блокирование доступа сотруднику;
Просмотр истории действий сотрудника.<br>
2.5. Лицензиар вправе дополнять и изменять функциональность Сервиса без уведомления Лицензиата.<br>
2.6. Лицензиат получает доступ к Сервису посредством Авторизации в Учетной записи через Сервис.<br>
2.7. Лицензиат соглашается с тем, что любое программное обеспечение может иметь ошибки. Сервис предоставляется на общепринятом в мировой практике принципе «таким, каков он есть» («as is»), то есть со всеми достоинствами и недостатками.<br><br>

<strong>3. Гарантии сторон</strong><br>
3.1. Лицензиар гарантирует, что является надлежащим правообладателем Сервиса, и что в Сервисе нет элементов, нарушающих права третьих лиц.<br>
3.2. Лицензия на право использования Сервиса, предоставляемая по Договору, действует на территории всех стран мира без ограничений.<br>
3.3. Лицензиар гарантирует доступ к Сервису в период действия Договора и защиту информации, имеющую отношение к Лицензиату и его клиентам.<br>
3.4. Лицензиат гарантирует использование Сервиса в порядке, в сроки и в соответствии с Договором, а также не будет нарушать исключительные права Лицензиара на Сервис, в том числе Лицензиат не будет копировать, модифицировать, переводить, декомпилировать, дизассемблировать, переконструировать или каким-либо другим способом предпринимать попытки переводить объектный код Сервиса.<br>
3.5. Размещая персональные, иные данные и сведения в Сервисе, Лицензиат дает согласие на то, что Лицензиар (и его уполномоченные представители и сотрудники) будет получать и хранить в электронном виде персональные данные Лицензиата, его клиентов и сотрудников по поручению Лицензиата. Лицензиат заверяет об обстоятельствах, что получил согласия от клиентов и сотрудников на обработку их персональных данных Лицензиаром. Цель обработки Лицензиаром персональных данных Лицензиата, его клиентов и сотрудников заключается только в предоставлении возможности использования Сервиса.<br>
3.6. Лицензиат дает согласие на то, что Лицензиар вправе направлять Лицензиату корреспонденцию на адрес электронной почты Лицензиата, информацию об обновлениях функционала Сервиса, проводимых Лицензиаром и его партнерами рекламных акциях, новых продуктах Лицензиара, и иную информацию.<br><br>

<strong>4. Права и обязанности сторон</strong><br>
4.1. В рамках Договора Лицензиат вправе использовать Сервис на условиях неисключительной лицензии:
использование Сервиса в соответствии с Договором для собственных целей на территории всего мира на любых устройствах Лицензиата.<br>
4.2. Лицензиат при использовании Сервиса обязан:<br>
- создать самостоятельно на свое имя Чат-бота и передать его данные Лицензиару;<br>
- своевременно заполнить анкету с вопросами от Лицензиара, чтобы Лицензиар мог выполнить настройку Сервиса в соответствии с ответами Лицензиата из анкеты;<br>
- своевременно и в порядке, установленном Договором, оплачивать вознаграждение Лицензиару;<br>
- незамедлительно сообщить Лицензиару при утрате или подозрения на утрату логина и/или пароля для доступа к Учетной записи;<br>
- не нарушать исключительные права Лицензиара на Сервис;<br>
- не передавать права использования Сервиса сублицензиатам;<br>
- не передавать данные доступа к Сервису третьим лицам;<br>
- допускать для работы с Сервисом в части обработки персональных данных только работников, которым такой доступ необходим для исполнения ими своих служебных обязанностей, назначенных приказом;<br>
- хранить пароли доступа к Сервису в секрете;<br>
- обеспечить размещение устройств ввода информации в Сервис, исключающее несанкционированный просмотр информации в Сервисе;<br>
- собирать и хранить согласия субъектов физических лиц, являющихся клиентами Лицензиата, на обработку персональных данных Лицензиаром перед началом обработки таких данных в Сервисе;<br>
- в случае технических неполадок, а также отсутствия доступа к Сервису, Лицензиат незамедлительно пишет в службу технической поддержки – в чат-бот Лицензиара в мессенджере «Telegram» https://t.me/LimzaBot;<br>
В случае досрочного расторжения Договора или отказа от его пролонгации, Лицензиат самостоятельно копирует всех свои данные из Сервиса на собственный ресурс.<br>
4.3. Лицензиар обязан:<br>
- предоставить Лицензиату анкету с вопросами по настройке Сервиса;<br>
- настроить Сервис в соответствии с ответами Лицензиара из анкеты;<br>
- предоставить Лицензиату логин и пароль от Учетной записи в течение 3 (трех) рабочих дней с даты зачисления оплаты от Лицензиата на расчетный счет Лицензиара;<br>
- приложить все усилия для предоставления Лицензиату Сервиса круглосуточно и непрерывно в сети Интернет;<br>
- обеспечивать сохранность данных Лицензиата и его клиентов, не передавать их третьим лицам, кроме как по запросам органов государственной власти в соответствии с действующим законодательством;<br><br>

<strong>5. Вознаграждение</strong><br>
5.1. Вознаграждение Лицензиара состоит из двух частей:<br>
5.1.1. вознаграждение за услуги: подключение Чат-бота Лицензиата к Сервису.<br>
5.1.2. лицензионное вознаграждение: ежемесячное вознаграждение за предоставление неисключительных срочных прав (простая неисключительная лицензия) за использование Сервиса.<br>
5.2. Размер вознаграждения за использование Сервиса и подключения к нему, а также порядок определения и расчета вознаграждения указаны на сайте по адресу https://limza.chat/tariff.<br>
5.3. Сроки уплаты вознаграждения: Лицензиат уплачивает Лицензиару вышеуказанные вознаграждения в течение 3 (трех) рабочих дней с даты получения счета от Лицензиара.
Вознаграждение за подключение Чат-бота (пункт 5.1.1 Договора) Лицензиат уплачивает Лицензиару в порядке предоплаты.
Ежемесячное лицензионное вознаграждение за использование Сервиса (пункт 5.1.2 Договора) Лицензиат уплачивает Лицензиару в порядке постоплаты по окончании календарного месяца использования Сервиса, и после получения счета от Лицензиара.
При досрочном расторжении Договора Лицензиат оплачивает лицензионное вознаграждение на основании счета Лицензиара, не дожидаясь окончания месяца использования Сервиса.
Ежемесячное лицензионное вознаграждение за использование Сервиса Лицензиат уплачивает за каждый активный чат в соответствии с тарифами, либо в размере минимального тарифа при отсутствии активных чатов.
Лицензионное вознаграждение подлежит оплате независимо от количества дней фактического пользования Сервисом Лицензиатом в месяце.
Под активным чатом понимается чат между Лицензиатом и его клиентом в Чат-боте, подключённом к Сервису, в котором Лицензиат отправил хотя бы один символ (букву, цифру, знак, пробел или иной символ) своему клиенту.
При досрочном отказе Лицензиата от договора до окончания календарного месяца, Лицензиат все равно уплачивается вознаграждение за каждый активный чат или минимальный тариф при отсутствии активных чатов в этом календарном месяце.<br>
5.4. Порядок уплаты вознаграждения: Лицензиат уплачивает Лицензиару 100% вознаграждения, указанного в счете на оплату Лицензиара, путем безналичного перечисления денежных средств на банковский счет Лицензиара. Оплата выставленного счета является согласием Лицензиата с указанным в счете размером вознаграждения.<br>
<strong>Сдача-приемка услуг:</strong> если Лицензиат не предъявил Лицензиару претензии перед оплатой ежемесячного лицензионного вознаграждения (пункт 5.1.2 Договора), то услуги за оплачиваемый период считаются оказанными надлежащим образом, качественно и своевременно. Акт сдачи-приемки услуг считается принятыми без разногласий.<br>
5.5. Изменение размера вознаграждения. Лицензиар вправе изменять размер вознаграждения, уведомив об этом Лицензиата не позднее 15 дней до изменения размера вознаграждения путем опубликования нового размера вознаграждения на сайте https://limza.chat/tariff или посредством уведомления через чат-бота в мессенджере «Telegram» https://t.me/LimzaBot.<br>
5.6. Если Лицензиат использует Сервис в периоде, следующем за уведомлением об изменении размера вознаграждения Лицензиара (тарифов на использование Сервиса), то это означает согласие Лицензиата на новый измененный размер вознаграждения и пролонгацию Договора на измененных условиях.<br>
5.7. При неиспользовании Лицензиатом Сервиса как в целом, так и в части по причинам, не зависящим от Лицензиара, полученное вознаграждение возврату не подлежит.<br>
5.8. При технических неполадках в Сервисе, возникших по вине Лицензиара, и повлекших невозможность использования Сервиса 3 дня и более Лицензиар вправе уменьшить размер вознаграждения в следующем месяце на сумму, рассчитываемую так:<br>
А=В/С*D, где<br>
А – сумма, на которую уменьшается вознаграждение Лицензиара в следующем месяце;<br>
В - сумма вознаграждения, причитающаяся Лицензиару в месяце возникновения неполадок;<br>
С - количество дней в месяце;<br>
D – количество дней неполадок в месяце возникновения неполадок.<br><br>

<strong>6. Акцепт оферты</strong><br>
6.1. Оплачивая первый по счету полученный от Лицензиара счет на оплату, Лицензиат производит Акцепт Оферты. С момента оплаты счета Договор между Лицензиаром и Лицензиатом считается заключенным<br>
6.2. Оплачивая первый полученный от Лицензиара счет, Лицензиат выражает свое полное и безоговорочное принятие всех условий этой Оферты без каких-либо изъятий и/или ограничений, в соответствии с положениями действующего ГК РФ.<br><br>
<strong>7. Регистрация, безопасность учетной записи</strong><br>
7.1. Авторизация Лицензиата в Сервисе осуществляется путем заполнения соответствующей формы. При Авторизации в Сервисе Лицензиат вводит имя пользователя (логин) и пароль, полученные от Лицензиара.<br>
7.2. Лицензиат согласен с тем, что он самостоятельно несет ответственность за сохранение конфиденциальности логина и пароля для доступа к Сервису. Также Лицензиат согласен с тем, что он несет исключительную ответственность перед Лицензиаром за все действия, которые совершены в подключенном к Сервису Чат-боте при использовании его (Лицензиата) логина и пароля для доступа к Сервису.<br>
7.3. Лицензиар активирует Учетную запись Лицензиата в Сервисе и выдает Лицензиату логин и пароль после оплаты Лицензиатом вознаграждения Лицензиару.<br>
7.4. Если Лицензиату станет известно о любом несанкционированном использовании его пароля, логина Учетной записи в Сервисе или утрату данных для доступа к Чат-боту, Лицензиат обязан незамедлительно уведомить об этом Лицензиара, выслав соответствующее электронное сообщение в чат-бот Лицензиара в мессенджере «Telegram» https://t.me/LimzaBot.<br>
7.5. Лицензиат обязан не размещать (а в случае размещения — самостоятельно нести ответственность в полном объеме) в Сервисе адреса электронной почты, и прочую личную информацию любых третьих лиц без их личного согласия на такие действия.<br><br>

<strong>8. Ответственность сторон</strong><br>
8.1. За неисполнение или ненадлежащее исполнение Сторонами Договора, Стороны несут ответственность в соответствии с действующим российским законодательством и Договором.<br>
8.2. Лицензиар не несет ответственности за какие-либо убытки (в том числе убытки в связи с неполучением прибыли, утратой деловой информации, причинением любого имущественного ущерба), возникающие у Лицензиата в связи с использованием или с невозможностью использования им Сервиса.<br>
8.3. Лицензиар не несет ответственности за качество каналов связи, настройки Чат-бота, работы мессенджера «Telegram», сети интернет, а также за перебои в работе, происходящие по причинам, не зависящим от Лицензиара.<br>
8.4. При нарушении Лицензиатом п. 4.1.—4.2. Договора, а также в случае неоплаты или несвоевременной оплаты Лицензиатом вознаграждения Лицензиару, Лицензиар вправе прекратить право использования Сервиса Лицензиатом без предварительного уведомления и отключить Чат-бота Лицензиата от Сервиса.<br>
8.5. Если у Лицензиата есть подозрение несанкционированного использования Сервиса, он обязуется немедленно уведомить об этом Лицензиара для блокировки Учетной записи Лицензиата до выяснения обстоятельств. Вплоть до получения указанного уведомления Лицензиар считает использование Сервиса осуществленным с согласия Лицензиата.<br>
8.6. Лицензиар не отвечает за Информацию, отправленные Лицензиатом в Чат-боте. Ответственность за направление информации как рекламного, так и иного характера, по факту отправки и по их содержанию несет Лицензиат.<br>
8.7. Лицензиат несет ответственность за любую Информацию (в том числе, но не ограничиваясь: файлы с данными, тексты и т. д.), которую он разместил в Чат-боте, подключенном к Сервису.<br>
8.8. При любых обстоятельствах ответственность Лицензиара в соответствии со статьей 15 Гражданского кодекса России не может превышать 5 000 (пяти тысяч) рублей и возлагается на него при наличии в его действиях вины.<br><br>

<strong>9. Форс-мажор</strong><br>
9.1. Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение Договора в случае действия обстоятельств непреодолимой силы, прямо или косвенно препятствующих исполнению Договора, то есть таких обстоятельств, которые не зависят от воли Сторон, чрезвычайные и непредотвратимые явления, события, воздействие которых происходит из вне и не зависит от субъективных факторов, не могли быть предвидены Сторонами в момент заключения Договора и предотвращены разумными средствами при их наступлении (форс-мажор).<br>
9.2. К обстоятельствам, указанным в п. 9.1. Договора, относятся: стихийные бедствия, ураганы, сход снежных лавин, эпидемии, землетрясения, наводнения, иные природные катаклизмы, а также военные действия, крупномасштабные забастовки, запретительные меры государства, акты органов власти, непосредственно затрагивающие предмет Договора.<br>
9.3. К обстоятельствам непреодолимой силы не относятся финансово-экономический кризис, недофинансирование или иные финансовые затруднения одной из Сторон.<br>
9.4. При невозможности исполнения Стороной обязательств вследствие непреодолимой силы она должна в течение 5 (пяти) календарных дней с даты наступления таких событий известить в другую Сторону о характере событий и предположительном периоде их действия, а также о причинно-следственной связи между событиями и невозможностью исполнения обязательства Стороной.<br>
9.5. При наступлении обстоятельств непреодолимой силы срок исполнения обязанностей по Договору отодвигается на период действия этих обстоятельств.<br>
9.6. Если обстоятельства непреодолимой силы продолжают действовать более 2 (двух) месяцев подряд, каждая из Сторон вправе расторгнуть Договор в одностороннем порядке путем направления другой Стороне соответствующего уведомления. Неуведомление или несвоевременное уведомление Стороны о начале обстоятельств непреодолимой силы лишает ее права ссылаться на них как на основание, освобождающее от ответственности за неисполнение обязательств по Договору.<br>
9.7. В случае спора о времени наступления, сроках и окончания определенных обстоятельств непреодолимой силы, заключение компетентного органа по месту нахождения соответствующей Стороны будет являться надлежащим и достаточным подтверждением начала, срока действия и окончания указанных обстоятельств.<br><br>

<strong>10. Условия расторжения договора</strong><br>
10.1. Каждая из Сторон имеет право досрочно расторгнуть Договор путем направления уведомления не менее чем за 20 (двадцать) рабочих дней до предполагаемой даты расторжения.<br>
10.2. При расторжении Договора Лицензиаром при отсутствии виновных действий Лицензиата, Лицензиар не взимает плату за использование Сервиса в месяце расторжения Договора Лицензиаром.<br>
10.3. Лицензиат вправе в любое время расторгнуть Договор, направив соответствующее уведомление Лицензиару не менее, чем за 20 рабочих дней до предполагаемой даты расторжения Договора.
В таком случае Лицензиар рассчитывает стоимость вознаграждения, причитающуюся ему от Лицензиата и неоплаченную за использование Сервиса до даты получения уведомления о расторжении договора Лицензиаром и выставляет соответствующий счет Лицензиату.
Лицензиат обязан оплатить такой счет. Только после получения оплаты вознаграждения Лицензиаром Договор будет считаться расторгнутым.<br><br>

<strong>11. Разрешение споров</strong><br>
11.1. Стороны обязаны прилагать все усилия к тому, чтобы путем переговоров решать все разногласия и споры, которые могут возникнуть по Договору, в связи с ним или в результате его исполнения.<br>
11.2. Споры и разногласия, которые невозможно решить путем переговоров, решаются в судебном порядке с обязательным соблюдением претензионного порядка. Срок ответа на претензию 14 (четырнадцать) календарных дней с даты получения ее Стороной.<br>
11.3. В случае недостижения согласия путем переговоров спор передается на рассмотрение в Арбитражный суд Российской Федерации.<br><br>
<strong>12. Прочие условия</strong><br>
12.1. Договор вступает в силу с момента Акцепта Оферты Лицензиатом, и действует до получения уведомления от Лицензиата о расторжении Договора.<br>
12.2. Лицензиар обязуется информировать Лицензиата об изменениях (дополнениях) Договора, публикуя новую редакцию Оферты в сети Интернет на сайте https://limza.chat. Изменения и дополнения к Договору вступают в силу с месяца, следующего за месяцем опубликования новой редакции Оферты.<br>
12.3. Лицензиат понимает и согласен с тем, что, если он пользуется Сервисом в месяце, следующем за месяцем опубликования новой Оферты, это расценивается как факт принятия Лицензиатом (акцепт Лицензиатом) измененных условий Договора.<br>
12.4. Лицензиат вправе отказаться от принятия изменений и дополнений в Договор, направив уведомление Лицензиару о таком отказе. Это означает отказ Лицензиата от использования Сервиса. Право Лицензиата пользования Сервисом сохраняется до истечения месяца, в котором была опубликована измененная Оферта.<br>
12.5. Во всем, что не оговорено в Договоре, Стороны руководствуются действующим законодательством РФ.<br>
12.6. Если какое-либо из положений Договора становится недействительным, это не затрагивает действительности остальных положений Договора.<br>
12.7. При изменении наименования, адреса, банковских реквизитов или реорганизации Стороны информируют друг друга в письменном виде или через средства электронной почты в трехдневный срок.<br>
12.8. Не вступая в противоречие с условиями Оферты, Лицензиар и Лицензиат вправе в любое время оформить Договор в форме письменного двухстороннего документа.<br><br><br>

<strong>13. Адрес и банковские реквизиты Лицензиара</strong><br>
ООО «Лимза<br>
ИНН: 5047265844<br>
Адрес: Россия, Москвоская облатсь, г. Химки, Юбилейный пр. 1, корп. 1<br>
Р/с: 40702810602500134899<br>
Банк:ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ" в Москве<br>
БИК: 044525999<br>
Корр/c: 30101810845250000999<br><br>

Директор ООО “Лимза”<br>
Ларионов И. М.
</p>
</div>
    </div>

</template>
<script>
export default {
  name: 'OffertaPage'
}
</script>
<style scoped>
.wrapper{
    margin-top: 50px;
}
a{
    text-decoration: none;
  }
table{
    margin: 50px auto;
    align-self: center;
    width: 50vw;
    min-width: 500px;
  }
caption{
    font-size: 1.1em;
    line-height: 2em;
    font-weight: 700;
    height: 3em;
  }
  th{
    width: 25vw;
  }
  td{
    text-align: center;
    height: 2.5em;
  }
  .note{
    margin: auto;
    width: 50vw;
    min-width: 500px;
    margin-bottom: 50px;
  }
  .grey{
    background-color: #d3d3d384;
  }
</style>
