<template>
    <div class="wrapper">
        <div class="first_block">
            <div class="first_block_text">
                <h1> <span id="h1-1">Create your own App</span> <span id="h1-2"><br>for your business</span> <span id="h1-3"><br>you don't need IT skills</span></h1>
                <h2>You run your own small business? Take your communication with clients to the next level! For You this is two in one - both messenger and App, without long and expensive development.</h2>
                <a href="https://t.me/LimzaBot" target="_blank" class="button">Find out the details</a>
            </div>
            <div class="phones">
                <div class="green_ring"></div>
                <div class="purp_ring"></div>
                <img src="../assets/ellipse.png" class="ellipse">
                <img src="../assets/phones.png" class="phones_img">
            </div>
        </div>
        <div class="second_block">
            <div class="block_text">
                <h3>Limza.CHAT functionality</h3>
                <div class="block_text_text">When you conect to the Limza.CHAT you take a messenger and App in one window. Your clients don’t need to download and install anything to their smartphones.</div>
            </div>
            <div class="abilities">
                <div class="abilities_laptop">
                    <img src="../assets/laptop.png" alt="laptop">
                </div>
                <div class="abilities_list">

                    <div class="abilities_item">
                        <div class="abilities_item_container blue">
                            <img class="abilities_item_container_img_2" src="../assets/pic12.png" alt="">
                            <img class="abilities_item_container_img" src="../assets/pic11.png" alt="">
                        </div>
                        <div class="abilities_item_text">Personalization of the App for different clients</div>
                    </div>
                    <div class="abilities_item">
                        <div class="abilities_item_container purp">
                            <img class="abilities_item_container_img" src="../assets/mess.png" alt="">
                        </div>
                        <div class="abilities_item_text">Online chat with clients</div>
                    </div>
                    <div class="abilities_item">
                        <div class="abilities_item_container green">
                            <img class="abilities_item_container_img" src="../assets/bell.png" alt="">
                        </div>
                        <div class="abilities_item_text">Automatic notifications to your clients</div>
                    </div>
                    <div class="abilities_item">
                        <div class="abilities_item_container light_blue">
                            <img class="abilities_item_container_img" src="../assets/reminder.png" alt="">
                        </div>
                        <div class="abilities_item_text">Automatic reminders to your clients</div>
                    </div>
                    <div class="abilities_item">
                        <div class="abilities_item_container gray">
                            <img class="abilities_item_container_img" src="../assets/dispatch.png" alt="">
                        </div>
                        <div class="abilities_item_text">Sending message to many clients at once</div>
                    </div>
                    <div class="abilities_item">
                        <div class="abilities_item_container purp">
                            <img class="abilities_item_container_img" src="../assets/modern.png" alt="">
                        </div>
                        <div class="abilities_item_text">Modern App functional</div>
                    </div>
                </div>
            </div>
            <div class="second_block_text_btn">
                <a href="https://t.me/LimzaBot" target="_blank" class="button center">Details</a>
            </div>
        </div>
        <div class="third_block">
            <div class="block_text">
                <h4>Do you work with regular clients or advise potential ones?</h4>
                <div class="block_text_text">Limza is the perfect solution for you!</div>
            </div>
            <div class="cards_container">
                <div class="cards">
                    <div class="cards_item">
                        <div class="cards_item_pic_container light_green">
                            <img class="cards_item_pic_container_pic" id="hat_pic" src="../assets/hat.png" alt="">
                        </div>
                        <div class="cards_item_text">
                            <div class="cards_item_text_name">
                                Education and courses for children and adults
                            </div>
                            <div class="cards_item_text_text">
                                Dividing customers for groups, test and quiz
                            </div>
                        </div>
                    </div>
                    <div class="cards_item">
                        <div class="cards_item_pic_container light_light_blue">
                            <img class="cards_item_pic_container_pic" src="../assets/beauty.png" alt="">
                        </div>
                        <div class="cards_item_text">
                            <div class="cards_item_text_name">
                                Beauty services
                            </div>
                            <div class="cards_item_text_text">
                                Dividing customers by category, self-booking service
                            </div>
                        </div>
                    </div>
                    <div class="cards_item">
                        <div class="cards_item_pic_container light_purp">
                            <img class="cards_item_pic_container_pic" src="../assets/sport.png" alt="">
                        </div>
                        <div class="cards_item_text">
                            <div class="cards_item_text_name">
                                Sport sections
                            </div>
                            <div class="cards_item_text_text">
                                Personal content for each group, feedback for parents
                            </div>
                        </div>
                    </div>
                    <div class="cards_item">
                        <div class="cards_item_pic_container train_blue">
                            <img class="cards_item_pic_container_pic" src="../assets/train.png" alt="">
                        </div>
                        <div class="cards_item_text">
                            <div class="cards_item_text_name">
                                Gyms and fitness centers
                            </div>
                            <div class="cards_item_text_text">
                                Customer service and online-booking time for training
                            </div>
                        </div>
                    </div>
                    <div class="cards_item">
                        <div class="cards_item_pic_container opacity_purp">
                            <img class="cards_item_pic_container_pic" src="../assets/goverment.png" alt="">
                        </div>
                        <div class="cards_item_text">
                            <div class="cards_item_text_name">
                                State and private schools
                            </div>
                            <div class="cards_item_text_text">
                                Information is available from any place and can be checked by manager
                            </div>
                        </div>
                    </div>
                    <div class="cards_item">
                        <div class="cards_item_pic_container opacity_green">
                            <img class="cards_item_pic_container_pic" src="../assets/blogger.png" alt="">
                        </div>
                        <div class="cards_item_text">
                            <div class="cards_item_text_name">
                                Bloggers
                            </div>
                            <div class="cards_item_text_text">
                                Gathering for marathons and subsequent communication
                            </div>
                        </div>
                    </div>
                    <div class="cards_item">
                        <div class="cards_item_pic_container opacity_blue">
                            <img class="cards_item_pic_container_pic" src="../assets/shop.png" alt="">
                        </div>
                        <div class="cards_item_text">
                            <div class="cards_item_text_name">
                                Online-shopping and online-srevices
                            </div>
                            <div class="cards_item_text_text">
                                Customer service, feedback collecting, top-up sales
                            </div>
                        </div>
                    </div>
                    <div class="cards_item">
                        <div class="cards_item_pic_container half_opacity_purp">
                            <img class="cards_item_pic_container_pic" src="../assets/business.png" alt="">
                        </div>
                        <div class="cards_item_text">
                            <div class="cards_item_text_name">
                                Self-employment people
                            </div>
                            <div class="cards_item_text_text">
                                Your own online-shop with customer support function
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fourth_block">
            <div class="bg">
                <div class="fourth_block_text">
                    <h5 class="fourth_block_text_name">Ready to join Limza.CHAT?</h5>
                    <div class="fourth_block_text_text"> Write now and get instructions for connection.</div>
                    <a href="https://t.me/LimzaBot" target="_blank" class="button">Write message</a>
                </div>
            </div>
            <img class="mob_bot" src="../assets/mob_bot.png" alt="phone">
        </div>
    </div>
</template>
<script>
export default {
  name: 'LandigPage'
}
</script>
<style scoped>
.wrapper{
    overflow: hidden;
    margin-top: 0;
    width: 100%;
}
.first_block{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    z-index: 99;
    margin-bottom: 120px;
}
.first_block_text{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}
h1{
    margin-top: 161px;
}
#h1-1{
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
}
#h1-2{
    font-weight: 400;
    font-size: 50px;
    line-height: 60px;
}
#h1-3{
    font-size: 50px;
    line-height: 60px;
    font-weight: 700;
    color: #6666cc;
    text-decoration: underline;
}
h2{
    margin-top: 30px;
    font-weight: 300;
    line-height: 28px;
}
.button{
    margin-top: 45px;
    width: fit-content;
}

.phones{
    position: relative;
    width: 525.15px;
    height: 545.61px;
    height: 100%;
    margin: 149px auto 0 auto;
}
.ellipse{
    position: absolute;
    height: 287.61px;
    width: 380.71px;
    left: 160px;
    top: 250px;
    border-radius: 0px;
}
.phones_img{
    position: absolute;
    width: 414px;
    height: 520px;
    left: 80px;
}
.green_ring{
    position: absolute;
    border-radius: 50%;
    width: 203px;
    height: 203px;
    background: #9AD2B2;
    top: 40px;
}
.purp_ring{
    position: absolute;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    left: 190px;
    background: #6666CC;
}

/* Second Block */
.second_block, .third_block{
    margin-top: 60px;
}
.block_text{
    max-width: 877px;
    width: 100%;
    margin: 0px auto 60px auto;
}
h3, h4, .block_text_text{
    text-align: center;
}
h3, h4{
    font-weight: 700;
    font-size: 38px;
    line-height: 47px;
    margin-bottom: 20px;
    width: 100%;
}
.abilities{
    display: grid;
    grid-template-columns: 68.33% 31.6%;
    column-gap: 5px;
}
.abilities_laptop{
    position: relative;
}
.abilities_laptop img{
    position: absolute;
    display: block;
    width: 100%;
    top: 30px;
}
.abilities_list{
    display: grid;
    grid-template-rows: repeat(6, 75px);
    row-gap: 10px;
}
.abilities_item{
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 15px;
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 8px 10px 14px #EDEDED, -8px -10px 14px #FCFCFC;
    backdrop-filter: blur(3.5px);
    border-radius: 14px;
}
.abilities_item_container{
    position: relative;
    width: 45px;
    height: 45px;
    border-radius: 8px;
}
.abilities_item_container_img{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -14px;
    margin-top: -15px;
}
.abilities_item_container_img_2{
    position: absolute;
    left: 39%;
    top: 35%;
    bottom: 0%;
}
.abilities_item_text{
    font-weight: 700;
    line-height: 22px;
    margin: auto 0;
}
.second_block_text_btn{
    text-align: center;
}
.cards{
    display: grid;
    grid-template-columns: repeat(4, 278px);
    grid-template-rows: repeat(2, 278px);
    gap: 29.33px 29.33px;
    margin: 0px auto;
    width: fit-content;
}
.cards_item{
    padding: 30px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 10px 10px 24px rgba(0, 0, 0, 0.02);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}
.cards_item_pic_container{
    width: 80px;
    height: 80px;
    border-radius: 15px;
    padding: 20px;
}
.cards_item_pic_container_pic{
    width: 100%;
}
#hat_pic{
    margin: 7.5px auto;
}
.cards_item_text_name{
    font-weight: 700;
    line-height: 22px;
    margin-top: 20px;
}
.cards_item_text_text{
    line-height: 22px;
    margin-top: 15px;
    font-size: 16px;
}
.fourth_block{
    margin-top: 120px;
    position: relative;
    height: 500px;
}
.mob_bot{
    position: absolute;
    left: 60%;
    bottom: 87px;
}
.bg{
    display: grid;
    grid-column: 1fr 1fr;
    position: absolute;
    width: 100%;
    height: 413px;
    background: #EDF7F1;
    border-radius: 50px;
    padding: 0px 90px;
    -webkit-box-shadow: 0px 40px 22px -22px rgba(223,233,229,0.59);
    -moz-box-shadow: 0px 40px 22px -22px rgba(223,233,229,0.59);
    box-shadow: 0px 40px 22px -22px rgba(223,233,229,0.59);
}
.fourth_block_text{
    margin-top: 60px;
    width: 600px;
}
.fourth_block_text_name{
    font-weight: 700;
    font-size: 38px;
    line-height: 47px;
}
.fourth_block_text_text{
    line-height: 28px;
    width: 400px;
}
@media (max-width: 1226px){
    .cards{
    grid-template-columns: repeat(3, 278px);
    grid-template-rows: repeat(3, 278px);
    }
}
@media (max-width: 1130px){
    #h1-1,#h1-2, #h1-3{
    font-size: 40px;
    line-height: 50px;
    }
    .phones{
    position: relative;
    width: 420.12px;
    height: 436.488px;
    height: 100%;
    margin: 143.2px auto 0 auto;
}
.ellipse{
    position: absolute;
    height: 230.088px;
    width: 310px;
    left: 128px;
    top: 200px;
}
.phones_img{
    position: absolute;
    width: 331.2px;
    height: 416px;
    left: 64px;
}
.green_ring{
    position: absolute;
    border-radius: 50%;
    width: 162.4px;
    height: 162.4px;
    background: #9AD2B2;
    top: 32px;
}
.purp_ring{
    position: absolute;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    left: 152px;
    background: #6666CC;
    }
    .abilities_laptop img{
        top: 40px;
    }
}
@media (max-width: 1040px){
    .abilities{
    grid-template-columns: 60% 40%;
    }
    .abilities_laptop img{
        top: 80px;
    }
}
@media (max-width: 932px){
    h1{
        margin-top: 80px;
    }
    #h1-1,#h1-2, #h1-3{
    font-size: 32px;
    line-height: 40px;
    }
    h2{
        font-size: 14px;
        line-height: 20px;
    }
    .phones{
    position: relative;
    width: 252.072px;
    height: 261.8928px;
    height: 100%;
    margin: 85.92px auto 0 auto;
    }
    .ellipse{
    position: absolute;
    height: 138.048px;
    width: 186px;
    left: 76.8px;
    top: 120px;
    }
    .phones_img{
    position: absolute;
    width: 198.72px;
    height: 249.6px;
    left: 38.4px;
    }
    .green_ring{
    position: absolute;
    border-radius: 50%;
    width: 97.44px;
    height: 97.44px;
    background: #9AD2B2;
    top: 19.2px;
    }
    .purp_ring{
    position: absolute;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    left: 91.2px;
    background: #6666CC;
    }
    .abilities_item_text{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    }
    .abilities{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 5px;
    }
    .abilities_laptop{
    grid-row: 1/2;
    width: 100%;
    }
    .abilities_laptop img{
    position: unset;
    display: block;
    }
    .abilities_list{
    grid-row: 2/3;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 10px;
    }
    .cards{
    grid-template-columns: repeat(2, 278px);
    grid-template-rows: repeat(4, 278px);
    }
    h3, h4, h5{
        font-size: 32px;
        line-height: 40px;
    }
    .bg{
    padding: 0px 30px;
    }
    .fourth_block_text{
    margin-top: 80px;
    width: 350px;
    }
    .fourth_block_text_text{
        line-height: 28px;
        font-size: 16px;
        width: 300px;
    }
    .mob_bot{
    left: 52%;
    }
}
@media (max-width: 767px){
    .first_block{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr fit-content(100%);
    align-items: center;
    z-index: 99;
    margin-bottom: 10px;
    }
    h1{
    margin-top: 20px;
    text-align: center;
    }
    #h1-1,#h1-2, #h1-3{
    font-size: 32px;
    line-height: 38px;
    }
    h2{
    margin-top: 30px;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    }
    .button{
        margin: 30px auto;
        font-size: 16px;
        line-height: 20px;
    }
    .phones{
    height: 280px;
    margin-top: 15px;
    }
    .abilities{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    row-gap: 5px;
    }
    .abilities_list{
    grid-row: 2/3;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    row-gap: 10px;
    }
    .abilities_laptop{
    grid-row: 1/2;
    width: 100%;
    }
    .abilities_laptop img{
    position: unset;
    display: block;
    }
    .abilities_item_text{
    font-size: 16px;
    line-height: 18px;
    }
    .cards{
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, 278px);
    }
    .cards_item_pic_container{
        margin: 0 auto;
    }
    .cards_item_text_name, .cards_item_text_text{
        text-align: center;
    }
    .second_block, .third_block, .fourth_block{
    margin-top: 30px;
    }
    .fourth_block{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        height: fit-content;
        margin-bottom: 30px;
    }
    .bg{
        position: unset;
        grid-row: 1/2;
        height: fit-content;
    }
    .mob_bot{
        display: none;
    }
    .fourth_block_text{
        text-align: center;
        margin-top: 15px;
        width: 100%;
    }
    .fourth_block_text_name{
        text-align: center;
        font-size: 32px;
        line-height: 38px;
        display: block;
        margin: 0 auto;
    }
    .fourth_block_text_text{
        margin-top: 14px;
        text-align: center;
        width: 100%;
    }
}
</style>
