<template>
    <div class="wrapper">
        <div class="tariff">
            <table>
                <caption>Tariffs Limza.CHAT</caption>
                    <thead>
                      <tr>
                        <th scope="col">Active chats*</th>
                        <th scope="col">Price for 1 month**, $.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="grey">
                        <th scope="row">Up to 100</th>
                        <td>19,99</td>
                      </tr>
                      <tr>
                        <th scope="row">From 101 to 200</th>
                        <td>37,99</td>
                      </tr>
                      <tr class="grey">
                        <th scope="row">From 201 to 300</th>
                        <td>53,99</td>
                      </tr>
                        <tr>
                        <th scope="row">From 301 to 400</th>
                        <td>67,99</td>
                      </tr>
                      <tr class="grey">
                        <th scope="row">From 401 to 500</th>
                        <td>79,99</td>
                      </tr>
                      <tr>
                        <th scope="row">From 501 to 600</th>
                        <td>89,99</td>
                      </tr>
                      <tr class="grey">
                        <th scope="row">From 601 to 700</th>
                        <td>97,99</td>
                      </tr>
                      <tr>
                        <th scope="row">From 701 to 800</th>
                        <td>103,99</td>
                      </tr>
                      <tr class="grey">
                        <th scope="row">From 801 to 900</th>
                        <td>107,99</td>
                      </tr>
                      <tr>
                        <th scope="row">From 901 to 1000</th>
                        <td>109,99</td>
                      </tr>
                      <tr class="grey">
                        <th scope="row">Over 1000</th>
                        <td>0,11 per 1 chat</td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="note">
                    *active chat is a chat that contains atleast 1 sent/recieved symbol<br>
                    **month or part of month if you started later than 1st day of month
                  </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'TariffPage'
}
</script>
<style scoped>
  a{
    text-decoration: none;
  }

  table{
    margin: 50px auto;
    align-self: center;
    width: 50vw;
    min-width: 500px;
  }
  caption{
    font-size: 1.1em;
    line-height: 2em;
    font-weight: 700;
    height: 3em;
  }
  th{
    width: 25vw;
  }
  td{
    text-align: center;
    height: 2.5em;
  }
  .note{
    margin: auto;
    width: 50vw;
    min-width: 500px;
    margin-bottom: 50px;
  }
  .grey{
    background-color: #d3d3d384;
  }
  @media (max-width: 767px){
    table{
    width: 100%;
    min-width: 100px;
    margin: 50px auto 20px auto;
    }
    th, td{
    text-align: center;
    font-size: 13px;
    height: 16px;
    }
    caption{
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    }
    .note{
      font-size: 12px;
      width: 100%;
      margin-bottom: 30px;
      min-width: 100px;
    }
  }
</style>
