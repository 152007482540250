<template>
      <div class="backg">
      <MyRing :style = "{
        background: '#9AD2B22B',
        filter: 'blur(200px)',
        width: '38.5vw',
        height: '38.5vw',
        left: '-17.6vw',
        top: '-110px'
        }"
        />
        <MyRing :style = "{
        background: '#6666CC2E',
        filter: 'blur(200px)',
        width: '27.57vw',
        height: '27.57vw',
        left: '90vw',
        top: '48px'
        }"
        />
        <MyRing :style = "{
        background: '#9966CC59',
        filter: 'blur(200px)',
        width: '32.85vw',
        height: '32.85vw',
        left: '56.53vw',
        top: '191px'
        }"
        />
        <MyRing :style = "{
        background: '#6666CC2E',
        filter: 'blur(200px)',
        width: '38.54vw',
        height: '38.54vw',
        left: '18.06vw',
        top: '1160px'
        }"
        />
        <MyRing :style = "{
        background: '#6666CC2E',
        filter: 'blur(200px)',
        width: '22.43vw',
        height: '22.43vw',
        left: '53.4vw',
        top: '1118px'
        }"
        />
        <MyRing :style = "{
        background: '#9AD2B221',
        filter: 'blur(200px)',
        width: '26.74vw',
        height: '26.74vw',
        left: '23.26vw',
        top: '492px'
        }"
        />
        <MyRing :style = "{
        background: '#9AD2B22B',
        filter: '',
        width: 25 + 'px',
        height: 25 + 'px',
        left: 560 +'px',
        top: 165 + 'px'
        }"
        />
        <MyRing :style = "{
        background: '#9AD2B22B',
        filter: '',
        width: 65 + 'px',
        height: 65 + 'px',
        left: 346 +'px',
        top: 695 + 'px'
        }"
        />
        <MyRing :style = "{
        background: '#6666CC2E',
        filter: '',
        width: 37 + 'px',
        height: 37 + 'px',
        left: 141 +'px',
        top: 901 + 'px'
        }"
        />
        <MyRing :style = "{
        background: '#97AFC917',
        filter: '',
        width: 102 + 'px',
        height: 102 + 'px',
        left: 1223 +'px',
        top: 1669 + 'px'
        }"
        />
        <MyRing :style = "{
        background: '#9AD2B25E',
        filter: '',
        width: 24 + 'px',
        height: 24 + 'px',
        left: 1351 +'px',
        top: 1825 + 'px'
        }"
        />
        <MyRing :style = "{
        background: '#9966CC26',
        filter: '',
        width: 47 + 'px',
        height: 47 + 'px',
        left: 90 +'px',
        top: 2255 + 'px'
        }"
        />
        <MyRing :style = "{
        background: '#9AD2B245',
        filter: 'blur(200px)',
        width: 555 + 'px',
        height: 555 + 'px',
        left: 286 +'px',
        top: 1916 + 'px'
        }"
        />
        <MyRing :style = "{
        background: '#6666CC2E',
        filter: 'blur(200px)',
        width: 450 + 'px',
        height: 450 + 'px',
        left: 699 +'px',
        top: 1968 + 'px'
        }"
        />
      </div>
  <div class="main_container">
  <header>
    <div class="header_container">
      <div class="logo">Limza.CHAT</div>
      <nav class="header_menu" v-if="(width > 767)">
        <ul class="menu_list">
          <li class="menu_item"><router-link to="/">Main</router-link></li>
          <li class="menu_item"><router-link to="/Tariff">Tariffs</router-link></li>
          <li class="menu_item"><a href="https://t.me/LimzaBot" target="_blank">Contacts</a></li>
        </ul>
        </nav>
      <Slide v-if="(width < 768)"
      :closeOnNavigation="true"
      noOverlay
      width="200"
      right
      >
        <div class="logo">Limza.CHAT</div>
        <nav class="header_menu">
        <ul class="menu_list">
          <li class="menu_item"><router-link to="/">Main</router-link></li>
          <li class="menu_item"><router-link to="/Tariff">Tariffs</router-link></li>
          <li class="menu_item"><a href="https://t.me/LimzaBot" target="_blank">Contacts</a></li>
        </ul>
        </nav>
        </Slide>
      <div class="header_menu-right" id="head_btn">
        <a href="https://t.me/LimzaBot" target="_blank" class="button">Try</a>
      </div>
    </div>
  </header>
  <router-view />
  <footer>
    <span>©Limza.CHAT 2023. All rights reserved</span>
    <router-link to="/Tariff" id="tariff">Tariffs</router-link>
    <!-- <router-link to="/Offerta" id="offerta">Оферта</router-link> -->
  </footer>
</div>
</template>
<script>
import MyRing from '@/components/UI/MyRing.vue'
import { Slide } from 'vue3-burger-menu'
export default {
  components: {
    MyRing,
    Slide
  },
  data () {
    return {
      width: null,
      isOpen: false
    }
  },
  methods: {
    updateWidth () {
      this.width = window.innerWidth
    }
  },
  created () {
    window.addEventListener('resize', this.updateWidth)
    this.updateWidth()
  }
}
</script>

<style>
@font-face {
font-family: "Gilroy";
src: url("../public/Gilroy-Regular.ttf") format("truetype");
font-style: normal;
font-weight: normal;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 18px;
  color: #000000;
}
:root {
  font-family: 'Gilroy';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --gap: 0.5em;
  --index: calc(1vw + 1vh);
  --shirina: calc(1vw);
}
#app{
  position: relative;
  height: 100%;
  line-height: 1;
}
.backg{
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  overflow: hidden;
}
.main_container{
  position: relative;
  margin: 0px auto;
  overflow: hidden;
  box-sizing: content-box;
  max-width: 1200px;
  padding: 0px 15px;
}

header{
  width: 100%;
  top: 0;
  left: 0;
  z-index: 50;
}
.header_container{
  display: flex;
  min-height: 59px;
  margin: 10px 0px;
}
.logo{
  width: 20%;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  align-self: center;
}
.header_menu{
  width: 60%;
}
.menu_list{
  list-style: none;
  display: flex;
  justify-content:flex-start;
  line-height: 59px;
  flex-wrap: wrap;
  margin-left: 120px;
}
.menu_item:not(:last-child) {
  margin-right: 60px;
}
.menu_item a{
  text-decoration: none;
}
.menu_item a:hover{
  color: #6666CC;
}
.menu_item a:active{
  color: #435781;
}
.header_menu-right{
  width: 20%;
  text-align:end;
  vertical-align: middle;
}
.button{
  display: inline-block;
  text-decoration: none;
  font-weight: 800;
  color: #fff;
  padding: 20px 30px 20px 30px;
  border-radius: 8px;
  box-shadow: 0px 14px 34px rgba(70, 70, 189, 0.3);
  background: linear-gradient(0deg, #6666CC, #6666CC), linear-gradient(180deg, #6666CC 0%, #4646BD 100%);
}
.button:hover{
  background: #6666CC;
}
.button:active{
  background: linear-gradient(0deg, #435781, #435781), linear-gradient(180deg, #6666CC 0%, #4646BD 100%);
}
/* colors */
.blue{
  background: #435781;
}
.light_blue{
  background: #4467AB;
}
.light_light_blue{
  background: #EDF1F5;
}
.opacity_blue{
  background: #4467AB0F;
}
.train_blue{
  background: #EAEFF8;
}
.green{
  background: #9AD2B2;
}
.light_green{
  background: #ECF7F1;
}
.opacity_green{
  background: #9AD2B217;
}
.purp{
  background: #6666CC;
}
.light_purp{
  background: #E0E2F3;
}
.opacity_purp{
  background: #6666CC2E;
}
.half_opacity_purp{
  background: #6666CC0F;
}
.gray{
  background: #97AFC9;
}
footer{
  display: grid;
  grid-template-columns: auto 100px 100px;
  font-size: 16px;
  padding: 15px 0px;
}
footer a{
  font-size: 16px;
}
@media (max-width: 932px){
  .logo{
  width: 20%;
  font-weight: 800;
  font-size: 28px;
  line-height: 30px;
  align-self: center;
  }
  .menu_item:not(:last-child) {
  margin-right: 40px;
  }
  .menu_item a{
  font-size: 16px;
  }
  .header_menu-right{
  width: 25%;
  }
}
@media (max-width: 810px){
.menu_list{
  margin-left: 70px;
}
}
@media (max-width: 767px){
  .menu_item:not(:last-child) {
  margin-right: 0px;
}
.logo{
  width: fit-content;
  font-weight: 800;
  font-size: 22px;
  line-height: 27.5px;
  align-self:flex-end;

}
#head_btn{
  display: none;
}
.header_container{
  position: relative;
 width: 100%;
 min-height: 27.5px;
}
.menu_list{
  margin: 0;
}
.menu_item{
  width: 100%;
}
.bm-burger-button {
      position: absolute !important;
      width: 24px !important;
      height: 20px !important;
      left: 80vw !important;
      top: 0px !important;
      cursor: pointer;
    }
    .bm-burger-bars {
      background-color: #6666CC !important;
    }
    .line-style {
      height: 20%;
      left: 0;
      right: 0;
    }
    .cross-style {
      top: 12px !important;
      right: 2px !important;
      cursor: pointer;
    }
    .bm-cross {
      background: #6666cc !important;
    }
    .bm-cross-button {
      height: 24px !important;
      width: 24px !important;
    }
    .bm-menu {
      background-color: #F5F5F5 !important;
      padding-top: 20px !important;
    }
    footer{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 1fr 1fr;
    row-gap: 15px;
    padding: 15px 0px;
    text-align: center;
    }
    footer span{
      grid-column: 1/3;
      font-size: 14px;
    }
    footer a{
      font-size: 14px;
    }
    #offerta{
      grid-column: 2/3;
      grid-row: 2/3;
    }
    #tariff{
      grid-column: 1/2;
      grid-row: 2/3;
    }

}
</style>
